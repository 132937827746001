import { mapState } from 'vuex';

import KnTable from '../../../shared/components/KnTable.vue'

export default {
  components: {
    KnTable,
  },
  props: {
    results: Array,
    pagination: Object,
    changePageResults: Function,
    searchResults: Function,
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Nombre', value: 'nombre_boleta', class: 'purple--text' },
        { text: 'Periodo', value: 'periodo', class: 'purple--text' },
        { text: 'Descargar', value: 'download', class: 'purple--text' },
      ],
      loading: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
  },
  watch: {
    // search: {
    //   async handler() {
    //     if (this.loading) return
    //     await this.searchResults()
    //   },
    // },
  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    actionCloseResults() {
      this.$emit('actionCloseResults')
    },
  }
}