import { getItem } from '../../../../api/api-methods'
import { apiMixin } from "../../../shared/mixins/apiMixin"

import KnSelect from '../../../shared/components/KnSelect.vue'

export default {
  components: {
    KnSelect
  },
  mixins: [apiMixin],
  props: {
    assignmentType: {
      type: String,
      default: 'Alumno'
    },
    groups: {
      type: Array,
      default: () => []
    },
    institutionId: {
      type: Number,
      default: null
    },
    groupId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      groupSelected: {},
      groupToAssignInfo: {
        dateRange: {
          to: null,
          from: null
        }
      },
      tuitionSelected: {},
      promptPaySelected: {},
      tuition: [],
      promptPaymentDiscounts: [],
      penalties: [],
      debtStatus: [],
      groupAction: 'Aprobar'
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: 'Cancelar',
          action: () => this.action1(),
          style: 'ml-auto',
          color: ''
        },
        {
          text: 'Guardar',
          action: () => this.action2(),
          style: '',
          color: 'primary'
        },
      ]
    }
  },
  async created() {
    try {
      const [penaltiesRes, promptPaymentDiscountsRes, debtStatusRes, tuitionRes] = await Promise.all([
        getItem(`/app-administracion/filters/penalizacion?institucion_educativa=${this.institutionId}`),
        getItem(`/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${this.institutionId}`),
        getItem(`/app-administracion/filters/estatus-adeudos?institucion_educativa=${this.institutionId}`),
        getItem(`/app-personas/filters/colegiatura?institucion_educativa=${this.institutionId}&estatus_sistema=true`)
      ]);
      this.penalties = penaltiesRes.results;
      this.promptPaymentDiscounts = promptPaymentDiscountsRes.results;
      this.debtStatus = debtStatusRes.results;
      this.tuition = tuitionRes.results;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },

  methods: {
    loadGroupInfo() {
      // console.log('Grupo seleccionado:', this.groupSelected);
      this.groupToAssignInfo.groupId = this.groupSelected.id
      this.groupToAssignInfo.dateRange.from = this.groupSelected.ciclo_escolar.fecha_inicio
      this.groupToAssignInfo.dateRange.to = this.groupSelected.ciclo_escolar.fecha_fin
    },
    selectSameGroup() {
      if (this.groupAction === 'Repetir') {
        const sameGroup = this.groups.find(group => group.id === this.groupId)
        this.groupSelected = sameGroup
        this.loadGroupInfo()
      } else {
        this.groupSelected = {}
        this.groupToAssignInfo.dateRange.from = null
        this.groupToAssignInfo.dateRange.to = null
      }
    },
    action1() {
      this.$emit('actionClose')
    },
    action2() {
      const objToSend = {
        ...this.groupToAssignInfo,
        group: this.groupSelected,
        tuition: this.tuitionSelected,
        promptPayDiscount: this.promptPaySelected
      }
      this.$emit('actionSave', objToSend)
    }
  }
}