import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { Actions } from '../../../shared/helpers/permissionContants';
import { can } from '../../../shared/helpers/permissionsUtils';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import KnMenu from '../../../shared/components/KnMenu.vue';
import { fetchPostStudentFamilyMembers } from '../../helpers/utilsStudent';

export default {
  components: { KnMenu },
  mixins: [paginationMixin],
  props: {
    tableTitle: {
      type: String,
      default: '',
    },
    rememberLastPage: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableBtnCreate: {
      type: Boolean,
      default: false,
    },
    showBottomAction: {
      type: Boolean,
      default: false,
    },
    showInactiveItems: {
      type: Boolean,
      default: false,
    },
    bottomActionText: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      search: null,
      expanded: [],
      siblingHeaders: [
        { text: 'Nombre completo', value: 'nombre_completo' },
        { text: 'Grupo', value: 'grupo' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      tutorHeaders: [
        {
          text: 'Nombre completo',
          value: 'nombre_completo',
          sortable: false,

        },
        {
          text: 'Responsable económico',
          value: 'responsable_economico',
          sortable: false,

        },
        {
          text: 'Correo electrónico',
          value: 'correo_electronico',
          sortable: false,

        },
        {
          text: 'Teléfono',
          value: 'telefono',
          sortable: false,

        },

      ], loadingFamilyData: false,
    };
  },
  computed: {
    ...mapState(['currentPage']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission']),
    bottomText() {
      return this.bottomActionText
        ? this.bottomActionText
        : `Ver ${this.tableTitle} inactivos`;
    },
    section() {
      return this.newButtonLabel.includes('alumno') ? 'Alumno' : '';
    },
    routeName() {
      return this.newButtonLabel.includes('alumno') ? 'Nuevo Alumno' : '';
    },
  },
  watch: {
    paginationCount() {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
  },
  methods: {
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),
    downloadDocument(url) {
      url
        ? window.open(url, '_blank')
        : console.error('La URL del documento es inválida.');
    },
    async onItemExpanded(expanded) {
      if (expanded.value) {
        await this.fetchFamilyData(expanded.item.id);
      }
    },

    async fetchFamilyData(item) {
      this.loadingFamilyData = true;
      try {
        const { data } = await fetchPostStudentFamilyMembers(item);
        const targetItem = this.items.find((i) => i.id === item);
        targetItem
          ? this.$set(targetItem, 'familia', data)
          : console.error(`Item no encontrado: ${item}`);
      } catch (error) {
        console.error('Error al obtener datos de la familia:', error);
      } finally {
        this.loadingFamilyData = false;
      }
    },

    editSibling(sibling) {
      this.$router.push({
        name: 'Editar Alumno',
        params: { id: sibling },
      });
    },
    viewAccountStatus(sibling) {
      this.$router.push({
        name: 'Estado de Cuenta Alumno',
        params: { id: sibling },
      });
    },
    viewReportCards(sibling) {
      this.emitActionResults(sibling);
    },
    menuOptions(item) {
      return [
        {
          title: 'Editar',
          action: () => this.goTo(`Editar ${this.section}`, { entity: item }),
          section: ['Alumno'],
          permissions: [Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Estado de cuenta',
          action: () =>
            this.goTo(`Estado de Cuenta ${this.section}`, { entity: item }),
          section: ['Alumno'],
          permissions: [Actions.view],
          active: true,
          disabled: false,
        },
        {
          title: 'Ver boletas',
          action: () => this.emitActionResults(item.documentos_boletas),
          section: ['Alumno'],
          permissions: [Actions.view],
          active: true,
          disabled: false,
        },
        {
          title: 'Dar de baja',
          action: () => this.emitAction2(item.id),
          section: ['Alumno'],
          permissions: [Actions.delete],
          active: true,
          disabled: false,
        },
        {
          title: 'Reactivar',
          action: () => this.emitAction2(item.id),
          section: ['Alumno'],
          permissions: [Actions.change],
          active: false,
          disabled: false,
        },
      ]
        .filter(
          (option) =>
            option.section.some((s) => s === this.section) &&
            option.active === !this.showInactiveItems
        )
        .map((option) => {
          option.permissions && option.permissions.length
            ? (option.disabled = !option.permissions.some((p) =>
              can({ actions: [p], resource: this.section })
            ))
            : (option.disabled = false);
          return option;
        });
    },
    goTo(routeName, params) {
      if (routeName.includes('Alumno')) {
        this.setSelectedId(params.entity.id);
        return this.$router.push({
          name: routeName,
          params: { id: params.entity.id },
        });
      }
      return this.$router.push({ name: routeName, params: params });
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    emitAction2(value) {
      this.$emit('action2', value);
    },
    emitShowAction() {
      this.clearSearch();
      this.$emit('showAction');
    },
    emitActionResults(value) {
      this.$emit('resultsAction', value);
    },
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
    emitSearch() {
      this.$emit('onSearch', this.page);
    },
    emitClearSearch() {
      this.clearSearch();
      this.$emit('onClearSearch');
    },
  },
};
