import { mapMutations, mapState, mapGetters } from 'vuex';

import {
  fetchStudentByName,
  fetchWithdrawStudent,
  reactivateStudent,
} from '../helpers/utilsStudent';

import { getItem } from '../../../api/api-methods';

import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnAssignGroupModal from '../components/KnAssignGroupModal/KnAssignGroupModal.vue';
import KnInactiveItemsTable from '../../shared/components/KnInactiveItemsTable.vue';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnStudentResultsViewerModal from '../components/KnStudentResultsViewerModal/KnStudentResultsViewerModal.vue';
import { StudentUtils } from '../mixins/StudentUtilsMixin';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../shared/helpers/permissionsUtils';
import KnTableStudent from '../components/KnTableStudent/KnTableStudent.vue';
import KnModalStudentUnenroll from '../components/KnModalStudentUnenroll/KnModalStudentUnenroll.vue';

export default {
  components: {
    KnAssignGroupModal,
    KnInactiveItemsTable,
    KnLocalAlert,
    KnStudentResultsViewerModal,
    KnTableStudent,
    KnModalStudentUnenroll
  },
  mixins: [paginationMixin, StudentUtils],
  data() {
    return {
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Grupo', value: 'grupo', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      inactiveItems: [],
      students: [],
      groups: [],
      openAssignGroupDialog: false,
      studentToAssign: {
        groupId: null,
      },
      showInactive: false,
      modalResults: false,
      studentResults: [],
      loading: false,
      expanded: [],
      showUnenrollModal: false,
      currentStudent: {},
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'currentPage']),
    ...mapGetters(['tutorId', 'hasAdminPermission']),
  },
  watch: {
    // search: {
    //   async handler() {
    //     await this.getStudents(!this.showInactive, this.search);
    //   },
    // },
    institutionId: {
      async handler() {
        this.setPaginationLimit(30);
        if (this.currentPage) this.setPaginationPage(this.currentPage);
        await this.fetchData(true);
      },
    },
  },
  async created() {
    this.setPaginationLimit(30);
    if (this.currentPage) this.setPaginationPage(this.currentPage);
    this.setIsLogin(false);
    await this.fetchData(true);
  },

  methods: {
    ...mapMutations(['setIsLogin', 'setCurrentPage']),
    canAdd: canAdd,
    canDelete: canDelete,
    async fetchData(visible) {
      try {
        const [groups] = await Promise.all([
          getItem(
            `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}`
          ),
          this.getStudents(visible, this.search),
        ]);
        this.showInactive = false;
        this.groups = groups.results;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    async getStudents(visible, search) {
      try {
        this.loading = true;
        let count;
        if (search) {
          const { data } = await fetchStudentByName(
            this.search,
            this.pagination,
            true,
            this.institutionId
          );
          count = data.count;
          this.students = data.results;
        } else {
          const limitFilter =
            this.pagination.limit !== null
              ? `&limit=${this.pagination.limit}`
              : '';
          const offsetFilter =
            this.pagination.offset !== 0
              ? `&offset=${this.pagination.offset}`
              : '';
          const tutors = this.tutorId ? `&tutores=${this.tutorId}` : '';
          const url = `/app-personas/filters/alumno?institucion_educativa=${this.institutionId}&estatus_sistema=${visible}${offsetFilter}${limitFilter}${tutors}`;
          const responseData = await getItem(url);
          count = responseData.count;
          this.students = responseData.results;
        }
        await this.processStudents();
        this.setPaginationCount(count);
      } catch (error) {
        console.error('Error fetching boletas:', error);
      } finally {
        this.loading = false;
      }
    },

    async changePageStudents(page = 1) {
      this.setPaginationPage(page);
      this.setCurrentPage(page);
      await this.getStudents(!this.showInactive, this.search);
    },

    async changePageResults(page = 1) {
      this.setPaginationPage(page);
      await this.getResults();
    },

    openModalResults(value) {
      this.modalResults = true;
      this.studentResults = value;
    },

    searchResults() {
      console.log('searching');
    },

    async processStudents() {
      const processedStudents = await Promise.all(
        this.students.map(async (stu) => {
          return await this.studentContructor(stu.id, stu, this.institutionId);
        })
      );
      this.items = processedStudents;
    },

    buttomActionText() {
      return this.showInactive
        ? 'Ver alumnos activos'
        : 'Ver alumnos inactivos';
    },

    tableTitle() {
      return this.showInactive ? 'Alumnos inactivos' : 'Alumnos';
    },

    openAssignGroup(value) {
      this.openAssignGroupDialog = true;
      // console.log('Se regresa el origen y el id del item?', value);
      this.studentToAssign = { ...value };
    },
    async assignGroupToStudent(/*groupObj*/) {
      // console.log('Se va  asignar grupo a Alumno', groupObj);
      // const responseAssign = await this.postObj('/personas/agregar-alumno-grupo', {
      //   id_alumno: this.studentToAssign.itemId,
      //   id_grupo: this.groupObj.groupId
      // })
    },
    async actionInactive(value) {
      try {
        this.items = [];
        this.resetPagination();
        this.setPaginationLimit(30);
        await this.getStudents(!value);
        this.showInactive = value;
      } catch (error) {
        console.error('Error during actionInactive:', error);
      }
    },

    async action2(studentId) {
      if (this.showInactive) await this.enableStudent(studentId);
      else await this.disableStudent(studentId);
    },

    async disableStudent(studentId) {
      try {
        if (canDelete('alumno')) {
          console.log(studentId);
          const student = this.items.find(element => element.id === studentId)
          console.log(student);
          this.currentStudent = student
          this.showUnenrollModal = true
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar alumno', error);
      }
    },
    closeModal(){
      this.showUnenrollModal = false
    },

    async enableStudent(studentId) {
      try {
        if (canChange('alumno')) {
          const { ok } = await reactivateStudent(studentId);
          if (ok) {
            const student = this.items.find(element => element.id === studentId)
            const formattedDate = new Date().toISOString().split('T')[0];
            await fetchWithdrawStudent({
              alumno: studentId,
              fecha_movimiento: formattedDate,
              institucion_educativa: student.institucion_educativa.id,
              motivo: 'Alta',
              tipo_movimiento: 'baja',            })
          }
          this.items = [];
          await this.getStudents(!this.showInactive, this.search);
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar un alumno', error);
      }
    },
  },
};
